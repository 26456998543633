// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  tarifsPrestaTtc: {
    recurent: 24,
    recurent_we: 30,
    ponctuel: 30,
    ponctuel_we: 38,
    recurent_gouvernante: 30,
    recurent_gouvernante_we: 50,
    ponctuel_gouvernante: 38,
    ponctuel_gouvernante_we: 70,
  },

  
  typeTransport: [
    {
      id: 2,
      ximiTransportTypeId: '-1',
      googleTravelMode: google.maps.TravelMode.WALKING,
      label: 'A pied',
      icon: 'marche.png',
    },
    {
      id: 5,
      ximiTransportTypeId: '0',
      googleTravelMode: google.maps.TravelMode.TRANSIT,
      label: 'Transports en commun',
      icon: 'bus.png',
    },
    {
      id: 3,
      ximiTransportTypeId: '1',
      googleTravelMode: google.maps.TravelMode.DRIVING,
      label: 'Voiture',
      icon: 'voiture.png',
    },
    {
      id: 1,
      ximiTransportTypeId: '3',
      googleTravelMode: google.maps.TravelMode.BICYCLING,
      label: 'Vélo',
      icon: 'velo.png',
    },
  ],

  production: false,
  appVersion: '0.0.21',
  GOOGLE_KEY_API: 'AIzaSyCPYUCP5IJKGRXu53J1rLIX3EivrxhxXOA',
  //dev stripe key
  //  stripeKey: 'pk_test_51JZEoJEX4JUeS8a3KOg8imOQz7xXiLJTPb9n2evTuLxuCo25GOB08JOZP8DDpsGRUjuZbIQz9xLEYo1yOl6eupXN00esew9YNY',
 
  //prod stripe public key
  stripeKey: 'pk_live_TuMYsfECP1qwm1LrOKEzkkG700bn6iyAst',

  // urlFront: 'http://localhost:4200',
  // urlFront: 'https://imageprod.wanteez.eu/',
  urlFront: 'https://wanteez.eu/',

  //urlApi: 'http://127.0.0.1:8000',
  // urlApi: 'https://93ca-194-51-224-19.ngrok.io',
  //  urlApi: 'https://imageapi.wanteez.eu',
  urlApi: 'https://api.wanteez.eu',

  // urlSocket: 'http://localhost:3000',
  urlSocket: 'https://websocket.wanteez.eu',

  urlApiImageProd: 'https://imageapi.wanteez.eu',
  urlSocketImageProd: 'https://websocket.imageprod.wanteez.eu',

  encryptSecretKey: 'KUoYdXvTvlrG8MxnyBQ4t3KfcBDWTALX',
  phoneWanteez: '0474110532',
  mailServicePaie: 'paie@wanteez.fr',
  mailServiceRh: 'rh@wanteez.fr',
  mailServiceCommercial:'contact@wanteez.fr',
  mailServiceComptable:'comptabilite@wanteez.fr',

  showLogs: false,
  perimetreRechercheDefault: 20,
  metersArrivedAtClient: 50,
  metersLeftFromClient: 50,

  fbPixelId: '545646394149907'
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
