import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Platform, ToastController, NavController, AlertController } from '@ionic/angular';
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import * as moment from 'moment';
import { ApiService } from './services/api/api.service';
import { AuthenticationStateService } from './services/authentication-state/authentication-state.service';
import { TokenAuthService } from './services/token-auth/token-auth.service';
import { SharedService } from './shared/shared.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { PixelService } from 'ngx-pixel';
import { environment } from 'src/environments/environment';
import { Facebook } from '@ionic-native/facebook/ngx';
// import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { NgZone } from '@angular/core';
import { AccountPage } from './pages/account/account.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  isApp;

  constructor(
    private statusBar: StatusBar,
    public plt: Platform,
    private authenticationStateService: AuthenticationStateService,
    private toastCtrl: ToastController,
    public shared: SharedService,
    private tokenAuthService: TokenAuthService,
    private nav: NavController,
    private fcm: FCM,
    private api: ApiService,
    private alertController: AlertController,
    public httpClient: HttpClient,
    private router:Router,
    // private pixelFb: PixelService,
    private fb: Facebook,
    private deeplinks: Deeplinks,
    private zone: NgZone,
    ) {

    this.plt.ready().then(() => {

      this.initDeeplinks();

      // TODO: Autorisation tracking ads facebook (pour iOS)
      this.fb.setAdvertiserTrackingEnabled(true);

      // TODO: La doc de facebook dit que ces deux lignes suivantes ne sont pas forcément nécessaires car fait automatiquement
      // this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_VIEWED_CONTENT);
      // this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ACTIVATED_APP);

      if(!localStorage.getItem('firstOpenApp')) {
        // this.pixelFb.trackCustom('DownloadApp', {
        //   appVersion: environment.appVersion,
        //   platform: this.plt.is('ios') ? 'ios' : 'android'
        // });
        this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ACTIVATED_APP); // TODO: nécessaire ou pas ?
        localStorage.setItem('firstOpenApp', 'true');
      }
      
      this.authenticationStateService.userAuthState.subscribe(state => {
        if (state) {
          this.router.navigate(['account']);
        } else {
          this.router.navigate(['auth/login']);
        }
      });

      // let status bar overlay webview
      this.statusBar.overlaysWebView(true);

      // Demande permission receive notifications
      this.fcm.hasPermission().then(hasPermission => {
        if (hasPermission) {
          console.log('Has permission!');
        }else{
          console.log('Has NOT permission!');
          this.fcm.requestPushPermission().then((success) => {
            console.log('ASK PERMISSION HERE');
            if (success) {
              console.log('get token');
              this.getFcmToken();
            }
          });
        }
      });

      // NOTIFICATION FIREBASE
      this.fcm.onNotification().subscribe(async data => {
        if (data.wasTapped) {
          console.log('Received in background');
          console.log(data);

          if(data.landing_page){
            this.nav.navigateForward(data.landing_page);
          }
        } else {

          console.log('Received in foreground');
          console.log(data);

          this.shared.getNotificationsOfUser();
          if(data.landing_page){
            await this.onReceiveNotificationForeground(data);
          }
        };
      });

      this.fcm.onTokenRefresh().subscribe(token => {
        // Register your new token in your back-end if you want
        // backend.registerToken(token);
        console.log('TOKEN onTokenRefresh', token);
        this.setTokenUser(token);
      });

      if (this.tokenAuthService.isSignedin()) {
        this.getFcmToken();
        this.subscribeToTopic();
        this.checkIfUserHaveFcmTokenValid();
      }

    });
  }

  initDeeplinks() {
    this.deeplinks.routeWithNavController(this.nav, { 
        '/account': ''
    }).subscribe(match => {
      console.log('Successfully matched route', match);
      // const path = `/${match.$route}/${match.$args['idScenario']}`;
      // const path = `/${match.$route}}`;
      // // Run the navigation in the Angular zone
      // this.zone.run(() => {
      //   this.router.navigateByUrl(path);
      // });
    },
    nomatch => {
      console.log("Deeplink that didn't match", nomatch);
    });

    // Si on est sur la webapp, on essaye d'ouvrir l'application Wanteez Clients
    let platforms = this.plt.platforms();
    console.log('PLATFORMS',platforms.join(', ')); 
    if((this.plt.is('android') || this.plt.is('ios')) && (document.URL.indexOf('http://localhost') === 0 || 
      document.URL.indexOf('ionic') === 0 || document.URL.indexOf('https://localhost') === 0)){
      this.isApp = true;
    }else if (this.plt.is('mobileweb') || this.plt.is('mobile')) {
      this.isApp = false;
      // document.location.href = "wanteezclients://account";
      setTimeout(()=>{
        window.open("wanteezclients://account", "_self"); // On ouvre l'appli si on est sur la webapp
      },500);
    }
    console.log('this.isApp',this.isApp)
  }

  getFcmToken() {
    this.fcm.getToken().then(token => {
      // Register your new token in your back-end if you want
      // backend.registerToken(token);
      console.log('TOKEN getToken', token);

      // Si le user n'a pas encore de FCM_token, ou si il est différent de cleui actuelle, on mets à jour
      const user: any = typeof this.authenticationStateService.userValue === 'string' ? JSON.parse(this.authenticationStateService.userValue) : this.authenticationStateService.userValue;
      // if(user && (!user?.fcm_token && user?.fcm_token !== user.fcm_token)){
      if(user){
        this.setTokenUser(token);
      }
    });
  }

  subscribeToTopic() {
    this.fcm.subscribeToTopic('newappversion');
  }

  async onReceiveNotificationForeground(data) {
    const notification = data.notification ? JSON.parse(data.notification) : null;
    if(!notification){
      return;
    }
    const alert = await this.alertController.create({
      header: notification.title,
      message: notification.body,
      buttons: [{
        text: 'Ok',
        handler: () => {
          this.nav.navigateForward(data.landing_page);
        }
      }],
      cssClass: 'w-alert'
    });
    await alert.present();
  }

  setTokenUser(token) {
    const user: any = typeof this.authenticationStateService.userValue === 'string' ? JSON.parse(this.authenticationStateService.userValue) : this.authenticationStateService.userValue;
    if(user){
      user.fcm_token = token;
      console.log('user_before_save', user);
      this.api.updateUserAccount(user).subscribe((res) => {
        if (res?.success) {
          this.authenticationStateService.setUserLogged(res.datas);
          localStorage.setItem('fcm_token', JSON.stringify({
            token,
            expiredAt: moment().add('1', 'hour')
          }));
        }
      });
    }
  }
  checkIfUserHaveFcmTokenValid(){
    const fcmToken = localStorage.getItem('fcm_token') ? JSON.parse(localStorage.getItem('fcm_token')) : null;
    if(fcmToken){
      if(moment() > moment(fcmToken.expiredAt)){
        this.fcm.getToken().then(token => {
          console.log('TOKEN getToken', token);
          this.setTokenUser(token);
        });
      }
    }else{
      this.fcm.getToken().then(token => {
        console.log('TOKEN getToken', token);
        this.setTokenUser(token);
      });
    }
  }

}
