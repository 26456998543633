import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
  name: 'checkAllHorraire'
})
export class CheckAllHorrairePipe implements PipeTransform {

  transform(mission, type = 'recurrent'): unknown {
    return getIdsUndefined(mission, type).length > 0 ? false : true;
  }

}

export function getIdsUndefined(mission, type = 'recurrent'): any[] {
     let arr = [];
     if (type === 'recurrent') {
          mission.horaires_recurents?.forEach(h => {
            const index = mission.interventions.findIndex(i => h.id === i.dt_dispo_client_id && (i.candidat_id === null));
            if (index >= 0) {
              if (!arr.includes(h.id)) {
                arr.push(h.id);
               }
            }
          });
     } else if (type === 'ponctuel') {
        mission.interventions?.forEach(inter => {
          if (!inter.candidat_id && !arr.includes(inter.id)) {
              arr.push(inter.id);
          }
        });
     }
     return arr;
}
