/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// import { listTemoignages } from 'src/app/shared/data/temoignage.model';
import { catchError, retry } from 'rxjs/internal/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { CryptoService } from '../crypto/crypto.service';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  urlApi = environment.urlApi;
  // geoApi = environment.geoApi;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json, ',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Authorization,  Content-Type, Accept',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      // 'Access-Control-Allow-Origin': '*',
    }),
  };

  httpFileOptions = {
    // reportProgress: true,
    // observe: 'events',
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json, ',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Authorization,  Content-Type, Accept',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      // 'Access-Control-Allow-Origin': '*',
    }),
  };

  httpOptionsGeo = {
    headers: new HttpHeaders({
      'Content-Type': 'text/plain',
      // 'X-PINGOTHER': 'pingpong',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }),
  };

  urlServeFile = environment.urlApi;

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document,
    private cryptoService: CryptoService,
    private router: Router,
    private iab: InAppBrowser,
    private plt: Platform,
    private appService: AppService
  ) {
    const url = document.location.protocol + '//' + document.location.hostname;
    // Si on est sur le localhost:4200, on ne fait pas appel à l'api qui est sur la même url
    // mais à l'url de l'api qui est dans environment.ts
    // if (document.location.hostname !== 'localhost') {
    //   this.urlApi = url;
    // }
    // if (document.location.hostname === 'localhost') {
    //   // this.urlServeFile = 'http://localhost:8000';
    //   this.urlServeFile = environment.urlApi;
    // }

    // Si on est en TEST => API ImageProd
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.urlApi = environment.urlApiImageProd;
      this.urlServeFile = this.urlApi;

    }

    this.appService.envChanged$.subscribe((resp) => {
      // Si on est en TEST => API ImageProd
      if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
        this.urlApi = environment.urlApiImageProd;
      }else{
        this.urlApi = environment.urlApi;
      }
      this.urlServeFile = this.urlApi;
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // WEB UTILS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  openFile(documentTypeId, filename, clientId = null, candidatId = null, isDownload = false) {
    const datas = {
      documentTypeId,
      filename,
      isDownload,
      clientId,
      candidatId,
    };
    const encodedDatas = this.cryptoService.encrypt(datas);
    if (filename) {
      if (this.plt.is('android') || this.plt.is('ios')){
      const browser = this.iab.create(this.urlServeFile + '/uploads/serve-file/' + encodedDatas, '_system', 'hideurlbar=yes,toolbarcolor=#C84F94,toolbarposition=top,hidespinner=true,zoom=no,hidenavigationbuttons=yes,closebuttoncaption=Fermer,closebuttoncolor=#ffffff');
      }else{
        window.open(this.urlServeFile + '/uploads/serve-file/' + encodedDatas, '_blank');
      }
    }
  }

  getEmbeddedFile(documentTypeId, filename, clientId = null, candidatId = null, isDownload = false) {
    const datas = {
      documentTypeId,
      filename,
      isDownload,
      clientId,
      candidatId,
    };
    const encodedDatas = this.cryptoService.encrypt(datas);
    if (filename) {
      return this.urlServeFile + '/uploads/serve-file/' + encodedDatas, '_blank';
    }
    return null;
  }

  openFileViewer(documentTypeId, filename: string, clientId = null, candidatId = null, isDownload = false): void {
    this.openFile(documentTypeId, filename, clientId, candidatId, isDownload);
    // const datas = {
    //   type,
    //   filename,
    // };
    // const encodedDatas = this.cryptoService.encrypt(datas);
    // if (filename) {
    //   const filen = filename.split('.');
    //   if (filen[filen.length - 1].toLocaleLowerCase() === 'pdf') {
    //     this.openFile(type, filename);
    //   } else {
    //     const url = this.router.serializeUrl(
    //       this.router.createUrlTree(['/viewer'], {
    //         queryParams: {
    //           url: this.urlServeFile + '/uploads/serve-file/' + filename,
    //         },
    //       })
    //     );
    //     window.open(url, '_blank');
    //   }
    // }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CANDIDATS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Création d'un candidat
   * @param candidat
   */
  createCandidat(candidat: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat', candidat, this.httpOptions)
      .pipe(catchError(this.handleError('createCandidat', candidat)));
  }

  /**
   * Création d'un candidat (step1)
   * @param candidat
   */
  createCandidatStep1(candidat: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat/step1', candidat, this.httpOptions)
      .pipe(catchError(this.handleError('createCandidatStep1', candidat)));
  }

  /**
   * Mise à jour d'infos du candidat
   * @param datas
   */
  updateCandidat(datas: any): Observable<any> {
    if(datas.id){
      datas.id = this.cryptoService.encrypt(datas.id);
    }
    return this.http
      .post<any>(this.urlApi + '/api/candidat/update', datas, this.httpOptions)
      .pipe(catchError(this.handleError('updateCandidat', datas)));
  }

  /**
   * Création d'un candidat
   * @param candidat
   */
  candidatureInfosSupp(candidat: any): Observable<any> {
    if(candidat.id){
      candidat.id = this.cryptoService.encrypt(candidat.id);
    }
    return this.http
      .post<any>(
        this.urlApi + '/api/candidat-infos-supp',
        candidat,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('candidatureInfosSupp', null)));
  }

  /**
   * Création d'espace intervenant
   * @param user 'email' & 'password'
   */
  createUserSpace(user): Observable<any> {
    return of({
      success: false,
    });
  }

  /**
   * L'envoi de code de validation
   * @param datas
   */
  sendValidationCode(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/users/send-validation-code',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('sendValidationCode', datas)));
  }

  /**
   * Création d'un compte utilisateur
   * @param client
   */
  createUserAccount(datas: any): Observable<any> {
    console.log('DATA CREATION', datas);
    return this.http
      .post<any>(this.urlApi + '/users/create-account', datas, this.httpOptions)
      .pipe(catchError(this.handleError('createUserAccount', datas)));
  }

  validUserAccount(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/users/valid-account',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('validUserAccount', datas)));
  }

 

  setInactifAccount(data): Observable<any> {
    if(data.clientId){
      data.clientId = this.cryptoService.encrypt(data.clientId);
    }
    return this.http
      .post<any>(this.urlApi + '/api/clients/setInactif', data, this.httpOptions)
      .pipe(catchError(this.handleError('setInactif', data)));
  }


  resetPassword(datas): Observable<any> {
    console.log('datas',datas)
    return this.http
      .post<any>(
        this.urlApi + '/api/users/reset-password',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('resetPassword', datas)));
  }

  updatePassword(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/users/change-password',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('resetPassword', datas)));
  }

   /**
   * Update user account
   * @param user
   */
  updateUserAccount(user): Observable<any> {
    console.log('User upadte url', this.urlApi + '/users/update');
    if(user.id){
      user.id = this.cryptoService.encrypt(user.id);
    }
    return this.http
      .post<any>(this.urlApi + '/api/users/update', user, this.httpOptions)
      .pipe(catchError(this.handleError('updateUserAccount', user)));
  }


  resendLienValidation(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/users/resend-lien-validation',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('resendLienValidation', datas)));
  }

  /**
   * Liste des jobs aux alentours du Candidat
   */
  getJobArround(localisation: any): Observable<any> {
    return this.http
      .get<any>(
        this.urlApi + '/api/candidat/JobArround',
        localisation /*this.httpOptions*/
      )
      .pipe(catchError(this.handleError('getJobArround', null)));
  }

  /**
   * Liste des intervenants aux alentours du client
   * @param data
   */
  getCandidatArround(data): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/candidats/candidats-around',
        data,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getCandidatArround', null)));
  }

  /**
   * Retourne les positions des candidats
   * @param client
   */
  candidatMapsPositions(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/candidat/maps-positions',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('candidatMapsPositions', null)));
  }

  /**
   * Retourne les positions des candidats
   * @param datas qui contient le nom et le prenom du candidat
   */
  candidatsByName(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat/name', datas, this.httpOptions)
      .pipe(catchError(this.handleError('candidatMapsPositions', null)));
  }

  /**
   * Sauvegarde les infos d'un candidat
   * @param client
   */
  saveCandidat(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat/save', datas, this.httpOptions)
      .pipe(catchError(this.handleError('saveCandidat', null)));
  }

  /**
   * Suppression d'un candidat
   * @param client
   */
  deleteCandidat(id: any): Observable<any> {
    return this.http
      .delete<any>(this.urlApi + '/api/candidat/delete/' + this.cryptoService.encrypt(id), this.httpOptions)
      .pipe(catchError(this.handleError('deleteCandidat', null)));
  }

  /**
   * Infos du candidat
   */
  getCandidat(id: any): Observable<any> {
    // Si on est en TEST => API ImageProd
    console.log('localStorage.getItem',localStorage.getItem('ENV'))
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.urlApi = environment.urlApiImageProd;
    }else{
      this.urlApi = environment.urlApi;
    }
    return this.http
      .get<any>(this.urlApi + '/api/candidat/' + this.cryptoService.encrypt(id))
      .pipe(catchError(this.handleError('getCandidat', null)));
  }
  /**
   * Liste des interlocuteurs
   */
  getInterlocuteurs(clientID): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/interlocuteurs/client/'+ this.cryptoService.encrypt(clientID))
      .pipe(catchError(this.handleError('getInterlocuteurs', null)));
  }


  /**
   * Liste des interlocuteurs
   */
   createInterlocuteurs(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/interlocuteurs' , datas, this.httpOptions)
      .pipe(catchError(this.handleError('createInterlocuteurs', null)));
  }

  updateInterlocuteurs(datas): Observable<any> {
    return this.http
      .patch<any>(this.urlApi + '/api/interlocuteurs' , datas, this.httpOptions)
      .pipe(catchError(this.handleError('createInterlocuteurs', null)));
  }


  /**
   * Liste des candidats
   */
  getCandidats(): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/candidats/')
      .pipe(catchError(this.handleError('getCandidats', null)));
  }

  /**
   * Liste des candidats par statut
   */
  getCandidatsByStatuts(datas, page = 1): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidats/search-by-statuts?page='+page, datas, this.httpOptions)
      .pipe(catchError(this.handleError('getCandidatsByStatuts', datas)));
  }

  /**
   * Recherche de candidats
   */
  searchCandidats(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidats/search', datas, this.httpOptions)
      .pipe(catchError(this.handleError('searchCandidats', null)));
  }

  /**
   * Liste des documents du candidat
   */
  getDocumentsCandidats(candidatId): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/candidats/documents/' + candidatId)
      .pipe(catchError(this.handleError('getDocumentsCandidats', null)));
  }

  /**
   * Supprime le document candidat
   */
  deleteDocumentCandidat(docId): Observable<any> {
    return this.http
      .delete<any>(
        this.urlApi + '/api/candidats/documents/delete/' + this.cryptoService.encrypt(docId),
        this.httpOptions
      )
      .pipe(catchError(this.handleError('deleteDocumentCandidat', docId)));
  }

  /**
   * Save Contrat signé
   */
  saveContratSigne(file): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidats/save-contrat-signe', file, this.httpOptions)
      .pipe(catchError(this.handleError('saveContratSigne', file)));
  }

  /**
   * Sauvegarde les infos d'un candidat
   * @param
   */
  getTextToImageBase64(text: string): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/tools/create-image-signature', {text}, this.httpOptions)
      .pipe(catchError(this.handleError('getTextToImageBase64', null)));
  }

  /**
   * Envoi du contrat de travail au candidat par mail
   * @param datas
   */
  sendContratTravail(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidats/send-contrat-travail', datas, this.httpOptions)
      .pipe(catchError(this.handleError('sendContratTravail', datas)));
  }

  /**
   * Sauvegarde du contrat de travail contre-signé par wanteez
   * @param datas
   */
  saveContratTravailContreSigne(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidats/save-contrat-travail-contresigne', datas, this.httpOptions)
      .pipe(catchError(this.handleError('saveContratTravailContreSigne', datas)));
  }

  /**
   * Suppression d'un commentaire candidat
   * @param id
   */
  deleteCommentaireCandidat(id: any): Observable<any> {
    return this.http
      .delete<any>(this.urlApi + '/api/candidats/commentaires/delete/' + this.cryptoService.encrypt(id), this.httpOptions)
      .pipe(catchError(this.handleError('deleteCommentaireCandidat', id)));
  }

  /**
   * Envoi du lien de complétion de la candidature par mail
   * @param datas
   */
  sendEmailEditCandidature(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidats/send-email-edit-candidature', datas, this.httpOptions)
      .pipe(catchError(this.handleError('sendEmailEditCandidature', datas)));
  }

  /**
   * Retourne les données relatifs au bilan de l'agent sur ces interventions réalisées
   * @param datas
   */
  getBilanAgent(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat/bilan', datas, this.httpOptions)
      .pipe(catchError(this.handleError('getBilanAgent', datas)));
  }

  /**
   * Retourne les données relatifs au bilan de l'agent sur ces interventions réalisées
   * @param datas
   */
  getBilanAgentExport(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat/bilan-export', datas, this.httpOptions)
      .pipe(catchError(this.handleError('getBilanAgentExport', datas)));
  }

  /**
   * Sauvegarde une démission avec la lettre de démission
   * @param datas
   */
  demissionAgent(datas: any): Observable<any> {
    // Create form data
    const formData = new FormData();
    formData.append('file', datas.file, datas.file.name);
    for (const [key, value] of Object.entries(datas)) {
      if(key !== 'file'){
        formData.append(key, value.toString());
      }
    }
    return this.http
      .post<any>(this.urlApi + '/api/candidat/demission', formData, this.httpFileOptions)
      .pipe(catchError(this.handleError('demissionAgent', datas)));
  }

  /**
   * Sauvegarde le justificatif d'une absence
   * @param datas
   */
  saveJustificatifAbsence(datas: any): Observable<any> {
    // Create form data
    const formData = new FormData();
    formData.append('file', datas.file, datas.file.name);
    for (const [key, value] of Object.entries(datas)) {
      if(key !== 'file'){
        console.log('value',value);
        if(value){
          formData.append(key, value.toString());
        }
      }
    }
    return this.http
      .post<any>(this.urlApi + '/api/candidat/absence/save-justificatif', formData, this.httpFileOptions)
      .pipe(catchError(this.handleError('saveJustificatifAbsence', datas)));
  }

  /**
   * Check le statut de l'agent pour l'appli
   * @param datas
   */
  checkStatutAgentForAppli(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat/check-status-agent-for-appli', datas, this.httpFileOptions)
      .pipe(catchError(this.handleError('checkStatutAgentForAppli', datas)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CANDIDATS COCKPIT
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Liste des candidats pour le cockpit recrutement
   */
  getCandidatsCockpitRecrutement(datas, page = 1): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat-cockpit/recrutement?page='+page, datas, this.httpOptions)
      .pipe(catchError(this.handleError('getCandidatsCockpitRecrutement', datas)));
  }

  /**
   * Affecte la tâche à l'user connecté
   */
  affectTacheRecrutement(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat-cockpit/recrutement/affect-tache', datas, this.httpOptions)
      .pipe(catchError(this.handleError('affectTacheRecrutement', datas)));
  }

  /**
   * Marque une cockpit note comme traitée
   */
  markCockpitNoteAsTraitee(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat-cockpit/recrutement/cockpit-note-traitee', datas, this.httpOptions)
      .pipe(catchError(this.handleError('markCockpitNoteAsTraitee', datas)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Factures
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * mise à jour d'une Facture
   * @param datas { id: facture_id, data: facture_field_key_value }
   */
  updateFacture(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/factures/update', datas, this.httpOptions)
      .pipe(catchError(this.handleError('updateFacture', datas)));
  }

  /**
   * envoyer une Facture
   * @param datas // facture: objet, client: objet
   */
  sendFacture(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/factures/send', datas, this.httpOptions)
      .pipe(catchError(this.handleError('sendFacture', datas)));
  }

  /**
   * Créer une Facture
   * @param datas // intervention_id // mission_id
   */
  createFacture(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/factures/create', datas, this.httpOptions)
      .pipe(catchError(this.handleError('createFacture', datas)));
  }

  /**
   * Obtenir une Facture
   * @param id id de la facture
   */
  getOneFacture(id): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/facture/' + this.cryptoService.encrypt(id), this.httpOptions)
      .pipe(catchError(this.handleError('getOneFacture', id)));
  }

  /**
   * Obtenir une list de facture Facture
   * @param page numero de la page
   * @param limit limite par page
   * @param type
   * @param period // today, week, last-week, month, last-month
   * @param name nom du client
   */
  getFactures(page, limit, type = null, period = null, name = null): Observable<any> {
    let params = `?page=${page}&limit=${limit}`;
    if (type) { params += `&type=${type}`; }
    if (period) { params += `&period=${period}`; }
    if (name) { params += `&name=${name}`; }
    return this.http
      .get<any>(this.urlApi + '/api/factures' + params, this.httpOptions)
      .pipe(catchError(this.handleError('getFactures', params)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ANNONCES
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Retourne les annonces des missions
   * @param client
   */
  missionsAnnonces(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/missions-annonces',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('missionsAnnonces', null)));
  }

  /**
   * Retourne les positions des annonces
   * @param client
   */
  annonceMapsPositions(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/annonces/maps-positions',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('annonceMapsPositions', null)));
  }

  /**
   * Retourne les positions des annonces
   * @param datas { lat, lng, distanceKM }
   */
  annonceAround(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/annonces/annonces-around',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('annonceAround', null)));
  }

  /**
   * Active plusieurs annonces d'un coup
   * @param datas
   */
  activeAllAnnoncesAround(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/annonces/activeAllAnnoncesAround', datas, this.httpOptions)
      .pipe(catchError(this.handleError('activeAllAnnoncesAround', datas)));
  }

  /**
   * Mise à jour d'infos d'une annonce
   * @param datas
   */
  updateAnnonce(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/annonce/update', datas, this.httpOptions)
      .pipe(catchError(this.handleError('updateAnnonce', datas)));
  }

  /**
   * Création d'une annonce
   * @param datas
   */
  createAnnonce(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/annonce/create', datas, this.httpOptions)
      .pipe(catchError(this.handleError('createAnnonce', datas)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CLIENTS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Création d'un client
   * @param client
   */
  createClient(client: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/client', client, this.httpOptions)
      .pipe(catchError(this.handleError('createClient', null)));
  }

  /**
   * Création devis client
   * @param client
   */
  createDevisClient(client: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/client/devis', client, this.httpOptions)
      .pipe(catchError(this.handleError('createDevisClient', null)));
  }


  /**
   * Création d'un stripe client stripe
   * @param client  { customer_id, client_id }
   */
  setStripeClientSecret(client): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/client/set-stripe-secret', client, this.httpOptions)
      .pipe(catchError(this.handleError('setClieClient', null)));
  }

  /**
   * Création d'un stripe client stripe
   * @param client  { customer_id, client_id }
   */
  createStripeCustomer(client): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/client/create-stripe-customer', client, this.httpOptions)
      .pipe(catchError(this.handleError('setClieClient', null)));
  }

  /**
   * Signature du devis client
   * @param client
   */
  signatureDevis(client: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/sign-devis-client',
        client,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('signatureDevis', null)));
  }

  /**
   * Envoi du devis non signé au client
   * @param client
   */
  sendDevisClientNonSigne(client: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/send-devis-client-non-signe',
        client,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('signatursendDevisClientNonSigneeDevis', null)
        )
      );
  }

  saveFilenameDevisSigne(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/devis/save-filename-devis-signe',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('saveFilenameDevisSigne', null)
        )
      );
  }

  /**
   * Retourne les positions des clients
   * @param client
   */
  clientsMapsPositions(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/clients/maps-positions',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('clientsMapsPositions', null)));
  }

  /**
   * Sauvegarde les infos d'un client
   * @param client
   */
  saveClient(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/client/save', datas, this.httpOptions)
      .pipe(catchError(this.handleError('saveClient', null)));
    }


    saveClientDepassement(datas: any): Observable<any> {
      return this.http
        .post<any>(this.urlApi + '/api/client/savedepassement', datas, this.httpOptions)
        .pipe(catchError(this.handleError('saveClientDepassement', null)));
      }
  
    

    saveClientContactsInfos(datas) {
      return this.http.post<any>(this.urlApi + '/api/clients/save-client-contacts', datas,this.httpOptions)
        .pipe(catchError(this.handleError('saveClientContactsInfos', datas)));
    }
  
    getClientContactsInfos(clientId) {
      return this.http.get<any>(this.urlApi + '/api/clients/client-contacts/'+ this.cryptoService.encrypt(clientId),this.httpOptions)
        .pipe(catchError(this.handleError('getClientContactsInfos', clientId)));
    }
  
    ////////////////////////////////////
    //// ClientPaymentMethods /////
    //////////////////////////////////
  
    getAllClientPaymentMethods(clientId) {
      return this.http.get<any>(this.urlApi + '/api/client/payment-method/'+ this.cryptoService.encrypt(clientId),this.httpOptions)
        .pipe(catchError(this.handleError('getAllClientPaymentMethods', clientId)));
    }
    
      /**
   * Suppression d'un moyen de paiement
   * @param datas 
   * @returns 
   */
  removeMoyenPaiement(id): Observable<any> {
    return this.http
      .delete<any>(this.urlApi + '/api/clients/moyen-paiement/remove/'+this.cryptoService.encrypt(id), )
      .pipe(catchError(this.handleError('removeMoyenPaiement', id)));
  }
    /**
   * Changement du moyen de paiement par défaut
   * @param datas 
   * @returns 
   */
  setDefaultMoyenPaiement(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/clients/moyen-paiement/default', datas, this.httpOptions)
      .pipe(catchError(this.handleError('setDefaultMoyenPaiement', datas)));
  }

  /**
   * Enregistrement du moyen de paiement
   * @param datas 
   * @returns 
   */
  saveMoyenPaiement(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/clients/moyen-paiement/save', datas, this.httpOptions)
      .pipe(catchError(this.handleError('saveMoyenPaiement', datas)));
  }

  /**
   * Get d'un stripe client secret si le client n'en a pas (pour saisie carte bancaire stripe)
   * @param datas 
   * @returns 
   */
  getNewStripeClientSecret(clientId): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/clients/moyen-paiement/get-stripe-client-secret/'+ this.cryptoService.encrypt(clientId), this.httpOptions)
      .pipe(catchError(this.handleError('getNewStripeClientSecret', clientId)));
  }
    
  /**
   * Sauvegarde l'expression du besoin du client
   * @param client
   */
  saveBesoinClient(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/client/save-besoin',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('saveBesoinClient', null)));
  }


  /**
   * Sauvegarde l'expression du besoin du client Brouillon
   * @param client
   */
   saveBesoinClientBrouillon(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/devis/brouillons/save',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('saveBrouillon', null)));
  }

  getBrouillonList(clientID: any): Observable<any> {
    // Si on est en TEST => API ImageProd
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.urlApi = environment.urlApiImageProd;
    }  

    return this.http
      .get<any>(this.urlApi + '/api/devis/brouillons/client/' + this.cryptoService.encrypt(clientID))
      .pipe(catchError(this.handleError('getBrouillon', null)));
  }


  getBrouillon(brouillonID: any): Observable<any> {
    // Si on est en TEST => API ImageProd
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.urlApi = environment.urlApiImageProd;
    }  

    return this.http
      .get<any>(this.urlApi + '/api/devis/brouillons/' + this.cryptoService.encrypt(brouillonID))
      .pipe(catchError(this.handleError('getBrouillon', null)));
  }


  deleteBrouilllon(brouillonID: any):Observable<any> {
    // Si on est en TEST => API ImageProd
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.urlApi = environment.urlApiImageProd;
    }  

    return this.http
      .delete<any>(this.urlApi + '/api/devis/brouillons/delete/' + this.cryptoService.encrypt(brouillonID))
      .pipe(catchError(this.handleError('getBrouillon', null)));
  }

  

  /**
   * ajout du besoin du client
   * @param client
   */
  addBesoinClient(datas: any): Observable<any> {
     return this.http
      .post<any>(
        this.urlApi + '/api/client/add-besoin',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('addBesoinClient', null)));
  }

  

  /**
   * Sauvegarde d'un client mutuelle
   * @param client
   */
  initClientMutuelle(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/client/init-client-mutuelle',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('initClientMutuelle', datas)));
  }

  /**
   * Création d'un compte utilisateur pour un client
   * @param client
   */
  createClientUserAccount(clientId, datas: any = null): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/client/create-client-user-account/'+ this.cryptoService.encrypt(clientId),
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('createClientUserAccount', clientId)));
  }

  /**
   * Infos du client
   */
  getClient(id: any): Observable<any> {
    // Si on est en TEST => API ImageProd
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.urlApi = environment.urlApiImageProd;
    }
    return this.http
      .get<any>(this.urlApi + '/api/client/' + this.cryptoService.encrypt(id))
      .pipe(catchError(this.handleError('getClient', null)));
  }

  /**
   * Liste des statuts des clients
   */
  getListsClientStatuts(): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/clients/client-statuts', this.httpOptions)
      .pipe(catchError(this.handleError('getListsClientStatuts', null)));
  }

  /**
   * Inserer un document client
   */
  addClientDocument(data): Observable<any> {
    return this.http
    .post<any>(this.urlApi + '/api/clients/documents', data)
    .pipe(catchError(this.handleError('getDocumentsClients', null)));
  }

  /**
   * Liste des documents du client
   */
  getDocumentsClients(clientId): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/clients/documents/' + this.cryptoService.encrypt(clientId))
      .pipe(catchError(this.handleError('getDocumentsClients', null)));
  }

  /**
   * Supprime le document client
   */
  deleteDocumentClient(docId): Observable<any> {
    return this.http
      .delete<any>(
        this.urlApi + '/api/clients/documents/delete/' + this.cryptoService.encrypt(docId),
        this.httpOptions
      )
      .pipe(catchError(this.handleError('deleteDocumentClient', docId)));
  }

  /**
   * Suppression du client
   * @param id
   */
  deleteClient(id: any): Observable<any> {
    return this.http
      .delete<any>(this.urlApi + '/api/client/delete/' + this.cryptoService.encrypt(id), this.httpOptions)
      .pipe(catchError(this.handleError('deleteClient', null)));
  }

  /**
   * Recherche de clients
   */
  searchClients(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/clients/search', datas, this.httpOptions)
      .pipe(catchError(this.handleError('searchClients', null)));
  }

  /**
   * Suppression d'un commentaire client
   * @param id
   */
  deleteCommentaireClient(id: any): Observable<any> {
    return this.http
      .delete<any>(this.urlApi + '/api/clients/commentaires/delete/' + this.cryptoService.encrypt(id), this.httpOptions)
      .pipe(catchError(this.handleError('deleteCommentaireClient', id)));
  }

  /**
   * Retourne les interventions d'un client (formatées en évenements FullCalendar)
   * @param candidatId
   * @param datas
   * @returns
   */
  getInterventionsOfClient(clientId, datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/clients/interventions/' + this.cryptoService.encrypt(clientId),
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getInterventionsOfClient', datas)));
  }

  /**
   * Retourne les clients autour d'un agent
   * @param candidatId
   * @param datas
   * @returns
   */
  getClientsAroundAgent(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/clients/clients-around',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getClientsAroundAgent', datas)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CLIENTS COCKPIT
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Liste des clients pour le cockpit commercial
   */
  getClientsCockpitCommercial(datas, page = 1): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/client-cockpit/commercial?page='+page, datas, this.httpOptions)
      .pipe(catchError(this.handleError('getClientsCockpitCommercial', datas)));
  }

  /**
   * Affecte la tâche à l'user connecté
   */
  affectTacheCommercial(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/client-cockpit/commercial/affect-tache', datas, this.httpOptions)
      .pipe(catchError(this.handleError('affectTacheCommercial', datas)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CONCIERGERIE
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Retourne les devis effectuées par une conciergerie
   * @param conciergerieId
   */
  getDevisOfConciergerie(conciergerieId: any): Observable<any> {
    return this.http
      .get<any>(
        this.urlApi + '/api/conciergerie/list-devis/' + conciergerieId,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getDevisOfConciergerie', null)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // DEVIS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Retourne les infos d'un devis
   * @param devisId
   * @param isSignatureElectronique
   * @returns
   */
  getDevis(devisId: any, isSignatureElectronique = false): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/devis/' + this.cryptoService.encrypt(devisId) + (isSignatureElectronique ? '/1' : ''))
      .pipe(catchError(this.handleError('getDevis', null)));
  }

  /**
   * Retourne les infos du devis du client
   * @param id
   * @returns
   */
  getDevisOfClient(clientId: any): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/devis/client/' + this.cryptoService.encrypt(clientId))
      .pipe(catchError(this.handleError('getDevisOfClient', null)));
  }

  /**
   * Création devis client libre
   * @param client
   */
  saveDevisLibre(client: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/devis/devis-libre', client, this.httpOptions)
      .pipe(catchError(this.handleError('saveDevisLibre', null)));
  }

  /*
   * Sauvegarde du devis signé électroniquement par le client
   * @param datas
   */
  signDevis(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/devis/sign', datas, this.httpOptions)
      .pipe(catchError(this.handleError('signDevis', datas)));
  }

  /**
   * Renvoi du devis au client par mail pour signature
   * @param datas
   */
  resendDevisForSignature(datas: any): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/devis/resend-devis-for-sign', datas, this.httpOptions)
      .pipe(catchError(this.handleError('resendDevisForSignature', datas)));
  }

  /**
   * Supprime un devis
   * @param datas
   */
  deleteDevis(id: any): Observable<any> {
    return this.http
      .delete<any>(this.urlApi + '/api/devis/delete/'+this.cryptoService.encrypt(id), this.httpOptions)
      .pipe(catchError(this.handleError('deleteDevis', id)));
  }

  /**
   * TODO: on utilise pas pour le moment
   * Enregistre les infos de paiement + créé l'abonnement dans stripe (pour les prestas récurentes)
   * @param datas
   * @returns
   */
  createSubscriptionPayment(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/devis/subscription',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getMissionsAPlanifier', datas)));
  }

  /**
   * TODO: on utilise pas pour le moment
   * Paiement stripe direct (pour les prestas ponctuels)
   * @param datas
   * @returns
   */
  createPayment(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/devis/payment', datas, this.httpOptions)
      .pipe(catchError(this.handleError('createPayment', datas)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // SCENARIOS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Retourne les scénarios créés pour le client
   * @param clientId 
   */
  getScenariosForClient(clientId){
    return this.http
      .get<any>(this.urlApi + '/api/scenarios/client/'+this.cryptoService.encrypt(clientId), this.httpOptions)
      .pipe(catchError(this.handleError('getScenariosForClient', null)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // LIST OPTIONS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Liste des options formulaire inscription candidat
   */
  getListsOptions(): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/list-options/', this.httpOptions)
      .pipe(catchError(this.handleError('getListsOptions', null)));
  }

  /**
   * Liste des statuts des candidats
   */
  getListsCandidatsStatuts(): Observable<any> {
    return this.http
      .get<any>(
        this.urlApi + '/api/candidats/candidats-statuts',
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getListsCandidatsStatuts', null)));
  }

  /**
   * Liste des compétences des candidats
   */
  getListsCandidatsCompetences(): Observable<any> {
    return this.http
      .get<any>(
        this.urlApi + '/api/candidats/candidats-competences',
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getListsCandidatsCompetences', null)));
  }

  /**
   * Liste des compétences des candidats
   */
  getListsCandidatsInfosSupp(): Observable<any> {
    return this.http
      .get<any>(
        this.urlApi + '/api/candidats/candidats-infos-supp',
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getListsCandidatsInfosSupp', null)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // MISSIONS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Retourne les missions à planifier
   * @param datas
   * @returns
   */
  getMissionsAPlanifier(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/missions/a-planifier',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getMissionsAPlanifier', datas)));
  }

  /**
   * Retourne les agents disponibles autour de la mission
   * @param datas
   * @returns
   */
  getAgentsDisposAroundMission(missionId, datas = null): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/missions/agents-dispos/' + missionId,
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(this.handleError('getAgentsDisposAroundMission', missionId))
      );
  }

  /**
   * Retourne les agents disponibles autour d'un client
   * @param datas
   * @returns
   */
  getAgentsDisposAroundClient(clientId, datas = null): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/clients/agents-dispos/' + this.cryptoService.encrypt(clientId),
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(this.handleError('getAgentsDisposAroundClient', clientId))
      );
  }

  /**
   * Retourne les agents disponibles pour les horaires souhaitées du client
   * @param datas
   * @returns
   */
  getAgentsDisposForHoraires(datas = null): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/candidats/agents-dispos-for-horaires',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getAgentsDisposForHoraires', datas)));
  }

  /**
   * Crée les interventions pour une mission
   * @param missionId
   * @param agentId
   * @param datas
   * @returns
   */
  createInterventionsForMission(
    missionId,
    agentId,
    datas = null
  ): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi +
          '/api/missions/create-interventions-for-mission/' +
          this.cryptoService.encrypt(missionId) +
          '/' +
          this.cryptoService.encrypt(agentId),
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('createInterventionsForMission', {
            missionId,
          })
        )
      );
  }

  createInterventionsClient(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi +
          '/api/interventions/create-interventions-client',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('createInterventionsClient', {
            datas,
          })
        )
      );
  }

  getInterventionAvis(interventionID): Observable<any> { 
    return this.http
      .get<any>(this.urlApi + '/api/intervention-avis/'+this.cryptoService.encrypt(interventionID)     
      )
      .pipe(catchError(this.handleError('get',
      )));
  }
  getInterventionAvisOfCandidat(candidatId): Observable<any> { 
    return this.http
      .get<any>(this.urlApi + '/api/intervention-avis/agent/'+this.cryptoService.encrypt(candidatId)     
      )
      .pipe(catchError(this.handleError('getAvisAgent',
      )));
  }

  noterInterventions(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi +
          '/api/intervention-avis',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('intervention-avis', {
            datas,
          })
        )
      );
  }

  pointageDebutIntervention(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi +
          '/api/intervention/pointageDebutIntervention',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('pointageDebutIntervention', {
            datas,
          })
        )
      );
  }

  pointageFinIntervention(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi +
          '/api/intervention/pointageFinIntervention',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('pointageFinIntervention', {
            datas,
          })
        )
      );
  }

  prolongationIntervention(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi +
          '/api/intervention/prolongationIntervention',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('prolongationIntervention', {
            datas,
          })
        )
      );
  }

  

  sendReponseProlongation(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi +
          '/api/intervention/sendReponseProlongation',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('sendReponseProlongation', {
            datas,
          })
        )
      );
  }

  askProlongationIntervention(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi +
          '/api/intervention/askProlongationIntervention',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('askProlongationIntervention', {
            datas,
          })
        )
      );
  }

  getInterventionProlongation(interventionId: any): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/interventions/getProlongationIntervention/' + this.cryptoService.encrypt(interventionId))
      .pipe(catchError(this.handleError('getProlongationIntervention', null)));
  }
  

  getInterventionsAgentAVenir(datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi +
          '/api/interventions/getInterventionsAgentAVenir',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('getInterventionsAgentAVenir', {
            datas,
          })
        )
      );
  }

  /**
   * Envoi un mail au service commercial pour rappeler un client
   * @returns
   */
   alertReCallCommercial( datas = null): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/alerte/client-ask-recall' ,
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('alertServiceCommercial', {
          })
        )
      );
  }



  /**
   * Envoi un mail au recrutement pour prévenir du manque d'agents sur une mission à affecter
   * @param missionId
   * @returns
   */
  alertRecrutementAucunAgentDispo(missionId, datas = null): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/alerte/no-agent-dispo/' + missionId,
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('alertRecrutementAucunAgentDispo', {
            missionId,
          })
        )
      );
  }

  /**
   * Création d'une mission pour un client
   * @param datas
   * @returns
   */
  createMission(datas): Observable<any> {
    return this.http
      .post<any>( this.urlApi + '/api/missions/create', datas, this.httpOptions )
      .pipe(
        catchError(
          this.handleError('createMission', datas )
        )
      );
  }

  /**
   * Infos de la mission
   */
  getMission(id: any): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/mission/' + this.cryptoService.encrypt(id))
      .pipe(catchError(this.handleError('getMission', null)));
  }

  /**
   * Récupérer les devis lié à la page besoins
   */

    getDevisBesoins(clientid: any): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/missions/missions-actives/' + this.cryptoService.encrypt(clientid))
      .pipe(catchError(this.handleError('missions-actives', null)));
  }




 
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // INTERVENTIONS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Retourne les interventions (formatées en évenements FullCalendar)
   * @param clientId
   * @param datas
   * @returns
   */
  getInterventionsEvents(clientId, datas): Observable<any> {
     return this.http
      .post<any>(
        this.urlApi + '/api/interventions/events-client/' + this.cryptoService.encrypt(clientId),
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getInterventionsEvents', datas)));
  }

  /**
   * Retourne les interventions d'un agent(formatées en évenements FullCalendar)
   * @param candidatId
   * @param datas
   * @returns
   */
  getInterventionsOfAgent(candidatId, datas): Observable<any> {
    // Si on est en TEST => API ImageProd
    if(localStorage.getItem('ENV') && localStorage.getItem('ENV') === 'TEST'){
      this.urlApi = environment.urlApiImageProd;
    }else{
      this.urlApi = environment.urlApi;
    }
    return this.http
      .post<any>(
        this.urlApi + '/api/candidats/interventions/' + this.cryptoService.encrypt(candidatId),
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getInterventionsOfAgent', datas)));
  }

  /**
   * Retourne les interventions d'une mission(formatées en évenements FullCalendar)
   * @param missionId
   * @param datas
   * @returns
   */
  getInterventionsOfMissionFormated(missionId, datas): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/missions/interventions/' + missionId,
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getInterventionsOfMissionFormated', datas)));
  }

  /**
   * Sauvegarde les infos d'une intervention
   * @param intervention
   */
  saveIntervention(intervention: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/intervention/save',
        intervention,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('saveIntervention', intervention)));
  }

  /**
   * Sauvegarde les infos d'une intervention
   * @param intervention
   */
  updateIntervention(intervention: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/intervention/update',
        intervention,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('updateIntervention', intervention)));
  }

  /**
   * Créations d'interventions
   * @param intervention
   */
  createInterventions(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/interventions/create',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('createInterventions', datas)));
  }

  /**
   * Créations d'interventions
   * @param intervention
   */
  createInterventionsFromScenarioApp(datas: any): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/interventions/create-from-scenario-app',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('createInterventionsFromScenarioApp', datas)));
  }

  /**
   * Retourne les absences d'un agent (pour fullcalendar)
   * @param candidatId
   * @param datas
   * @returns
   */
  getAbsencesAgent(candidatId, datas: any = []): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/interventions/getAbsencesAgent/'+candidatId,
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('getAbsencesAgent', datas)));
  }

  /**
   * Infos de l'intervention
   */
  getIntervention(id: any): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/intervention/' + this.cryptoService.encrypt(id))
      .pipe(catchError(this.handleError('getIntervention', null)));
  }

  /**
   * Infos de l'intervention (sans la mission)
   */
  getInterventionNoMission(id: any): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/intervention-no-mission/' + this.cryptoService.encrypt(id))
      .pipe(catchError(this.handleError('getInterventionNoMission', null)));
  }

  /**
   * Retourne les interventions d'une mission
   */
  getInterventionsOfMission(missionId: any): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/interventions/mission/' + missionId)
      .pipe(catchError(this.handleError('getInterventionsOfMission', null)));
  }

  /**
   * Suppression d'une intervention
   * @param client
   */
  deleteIntervention(id: any, dtDispoClientId: any): Observable<any> {
    let url = '/api/intervention/delete/' + this.cryptoService.encrypt(id);
    if(dtDispoClientId){
      url += '/' + dtDispoClientId;
    }
    return this.http
      .delete<any>(
        this.urlApi + url,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('deleteIntervention', null)));
  }



  
 /**
   * Edition d'une interention
   * @param datas
   * @returns
   */
  editIntervention( datas: any = []): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/intervention/editIntervention-client',
        datas,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('editIntervention', datas)));
  }

  /**
   * Suppression d'une intervention
   * @param client
   */
   cancelIntervention(id: any): Observable<any> {
    let url = '/api/intervention/cancelIntervention-client/' + this.cryptoService.encrypt(id);
 
    return this.http
      .post<any>(
        this.urlApi + url,
        this.httpOptions
      )
      .pipe(catchError(this.handleError('cancelIntervention', null)));
  }


  

  /**
   * Envoi un mail au client avec les interventions créées
   * @param datas
   * @returns
   */
  notifyInterventionsClient(datas = null): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/interventions/notify-interventions-client',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(this.handleError('notifyInterventionsClient', datas))
      );
  }

  /**
   * Envoi un mail à l'agent avec les interventions créées
   * @param datas
   * @returns
   */
  notifyInterventionsAgent(datas = null): Observable<any> {
    return this.http
      .post<any>(
        this.urlApi + '/api/interventions/notify-interventions-agent',
        datas,
        this.httpOptions
      )
      .pipe(
        catchError(this.handleError('notifyInterventionsAgent', datas))
      );
  }

  ////////////////////////////////////
  //// Formulaire Infos Clients /////
  //////////////////////////////////
  sendVueFormulaire(date) {
    return this.http
      .post<any>(
        this.urlApi + '/api/formulaire/vue',
        { date },
        this.httpOptions
      )
      .pipe(catchError(this.handleError('vueFormulaireInfosClient', { date })));
  }

  sendAddFormulaire(date, client_id) {
    return this.http
      .post<any>(
        this.urlApi + '/api/formulaire/ajout',
        { date, client_id },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.handleError('ajoutFormulaireInfosClient', { date, client_id })
        )
      );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  GEOLOC
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Retourne les résultats de la géolocalisation https://api-adresse.data.gouv.fr
   */
  geolocByAdresse(adresse): Observable<any> {
    return this.http
      .get<any>(
        // 'http://api-adresse.data.gouv.fr/search/?q=' +
        this.urlApi + '/api/tools/geoloc-by-adresse-api-gouv/' +
          encodeURIComponent(adresse),
        this.httpOptions
      )
      .pipe(catchError(this.handleError('geolocByAdresse', adresse)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  LOG
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Log la nouvelle session
   */
  logSession(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/log/session', datas, this.httpOptions)
      .pipe(catchError(this.handleError('logSession', datas)));
  }

  /**
   * Log la nouvelle page visité
   */
  logVisitedPage(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/log/visited-page', datas, this.httpOptions)
      .pipe(catchError(this.handleError('logVisitedPage', datas)));
  }

  /**
   * Log un évenement (click bouton)
   */
  logEvent(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/log/event', datas, this.httpOptions)
      .pipe(catchError(this.handleError('logEvent', datas)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  CONGES
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Sauvegarde d'un nouveau congé d'agent
   */
  saveCongesAgent(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat/conges/save', datas, this.httpOptions)
      .pipe(catchError(this.handleError('saveCongesAgent', datas)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  ABSENCE
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Sauvegarde d'une nouvelle absence d'un agent
   */
  saveAbsenceAgent(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat/absences/save', datas, this.httpOptions)
      .pipe(catchError(this.handleError('saveAbsenceAgent', datas)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  RETARDS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Sauvegarde d'une nouvelle absence d'un agent
   */
  saveRetardAgent(datas): Observable<any> {
    return this.http
      .post<any>(this.urlApi + '/api/candidat/retards/save', datas, this.httpOptions)
      .pipe(catchError(this.handleError('saveRetardAgent', datas)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  NOTIFICATIONS
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Retourne les notifications de l'utilisateur
   */
  getNotificationsOfUser(userId): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/notifications/user/'+this.cryptoService.encrypt(userId), this.httpOptions)
      .pipe(catchError(this.handleError('getNotificationsOfUser', userId)));
  }

  /**
   * Marque une notification comme lue
   */
  markNotifAsRead(notifId): Observable<any> {
    return this.http
      .get<any>(this.urlApi + '/api/notifications/'+this.cryptoService.encrypt(notifId)+'/read', this.httpOptions)
      .pipe(catchError(this.handleError('markNotifAsRead', notifId)));
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //// REQUEST API GEOCODE
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // onAutocomplete(query) {
  //   return new Promise((res, rej) => {
  //     const xmlHttp = new XMLHttpRequest();
  //     xmlHttp.responseType = 'json';
  //     const url = `${this.geoApi}/search/?q=${query}&type=housenumber&autocomplete=1&limit=8`;
  //     xmlHttp.onreadystatechange = () => {
  //       if (xmlHttp.readyState === 4) {
  //         if (xmlHttp.status === 200) {
  //           res(xmlHttp.response.features);
  //         } else {
  //           rej(xmlHttp.response);
  //         }
  //       }
  //     };
  //     xmlHttp.open('GET', url, true); // true for asynchronous
  //     xmlHttp.send(null);
  //   }).catch(this.handleError('logEvent', null));
  // }

  getJourFerie(): Promise<any> {
    return new Promise((res, rej) => {
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.responseType = 'json';
      const url = 'https://calendrier.api.gouv.fr/jours-feries/metropole.json';
      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState === 4) {
          if (xmlHttp.status === 200) {
            res(Object.keys(xmlHttp.response));
          } else {
            rej(rej(false));
          }
        }
      };
      xmlHttp.open('GET', url, true); // true for asynchronous
      xmlHttp.send(null);
    }).catch(this.handleError('logEvent', null));
  }

/**
 * @param id
 * @returns
 */
  getFacturesClient(id, start_date, end_date, statut = null, mutuelle = false): Observable<any> {
  return this.http.post(this.urlApi + '/api/clients/factures/' +  this.cryptoService.encrypt(id), {id: this.cryptoService.encrypt(id), start_date, end_date, statut, mutuelle},this.httpOptions)
  .pipe(catchError(this.handleError('GetFactureClient', id)));
}

  genererFacture(data, organisme = false) {
    return this.http
    .post<any>(this.urlApi + '/api/clients/generer/factures', { facture: data, organisme }, this.httpOptions)
    .pipe(catchError(this.handleError('sendFacture', { data })));
  }

/**
   * Envoi un mail à l'agent avec les interventions créées
   * @param datas
   * @returns
   */
 getInterventionAgentEnCours(datas = null): Observable<any> {
  return this.http
    .post<any>(
      this.urlApi + '/api/interventions/getInterventionAgentEnCours',
      datas,
      this.httpOptions
    )
    .pipe(
      catchError(this.handleError('getInterventionAgentEnCours', datas))
    );
}


  //////////////////
  // Temoignages //
  ////////////////
  // getTemoignages(): Observable<any> {
  //   // return this.http
  //   // .get<any>(this.urlApi + '/api/temoignages')
  //   // .pipe(catchError(this.handleError('getTemoignages', null)));
  //   return of({
  //     success: true,
  //     data: listTemoignages,
  //   });
  // }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // HANDLE ERROR
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /**
   * Handle Error http request
   * @param operation
   * @param result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  /**
   * Log
   * @param message
   */
  private log(message: string) {
    console.log(message);
  }
}
