/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/shared/data/User';
import { TokenAuthService } from '../token-auth/token-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationStateService {

  private userCurrentState = new BehaviorSubject<boolean>(
    this.tokenAuthService.isSignedin()
  );
  userAuthState = this.userCurrentState.asObservable();

  private userLogged = new BehaviorSubject(
    // JSON.parse(JSON.stringify(localStorage.getItem('currentUser'))) || User
    JSON.parse(localStorage.getItem('currentUser')) || User
  );
  userLogged$ = this.userLogged.asObservable();

  constructor(
    public tokenAuthService: TokenAuthService,
    public router: Router,
    // private notifier: NotifierService
  ) {}

  // tslint:disable-next-line:ban-types
  public get userValue(): any {
    return this.userLogged.value;
  }

  setAuthState(value: boolean): void {
    this.userCurrentState.next(value);
  }

  setUserLogged(value: any) {
    localStorage.setItem('currentUser', JSON.stringify(value));
    this.userLogged.next(value);
  }

  check(): boolean {
    let isLogged = false;
    const user: any =  typeof this.userValue === 'string' ? JSON.parse(this.userValue) : this.userValue;
    if (user) {
      if (user.id) {
        isLogged = true;
      }
    }
    return isLogged;
  }

  checkSession(): void {
    const user: any =  typeof this.userValue === 'string' ? JSON.parse(this.userValue) : this.userValue;
    if (user) {
      if (user?.roles_ids?.includes(5) || user?.roles_ids?.includes(6)) {
        this.router.navigate(['/account']);
        // this.notifier.notify('success', 'Vous êtes déjà connecté!');
      } else if (
        user?.roles_ids?.length > 0 &&
        !user?.roles_ids?.includes(5) &&
        !user?.roles_ids?.includes(6)
      ) {
        this.router.navigate(['/admin']);
        // this.notifier.notify('success', 'Vous êtes déjà connecté!');
      }
    }
  }

  userHasRole(roleId): boolean {
    let hasRole = false;
    const user: any = typeof this.userValue === 'string' ? JSON.parse(this.userValue) : this.userValue;
    if (user) {
      if (user?.roles_ids?.includes(roleId)) {
        hasRole = true;
      }
    }
    return hasRole;
  }

  userIsManager(): boolean {
    let isManager = false;
    const user: any = this.userValue;
    if (user) {
      if (user?.role_manager) {
        isManager = true;
      }
    }
    return isManager;
  }
}
