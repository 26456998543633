import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ApiService } from '../services/api/api.service';
import { AuthenticationStateService } from '../services/authentication-state/authentication-state.service';
import { IonLoaderService } from '../services/ion-loader/ion-loader.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

/**
   *  le client a seulement un Cesu
   */
  clientOnlyCesu
  /**
   * Filtre historique
   */
   typeHistorique = 'day';
  /**
   * Filtre agenda
   */
   typeAgenda = 'day';

   

/**
   * Clicked Missio,
   */
 ClickedMission : any;

 /**
   * Vu agenda
   */
  public ViewAgenda : string = 'ponctuel';

  HEURES_RANGER: Array<any> = [
    { value: 0, legend: '00:00' },
    { value: 0.5, legend: '00:30' },
    { value: 1, legend: '01:00' },
    { value: 1.5, legend: '01:30' },
    { value: 2, legend: '02:00' },
    { value: 2.5, legend: '02:30' },
    { value: 3, legend: '03:00' },
    { value: 3.5, legend: '03:30' },
    { value: 4, legend: '04:00' },
    { value: 4.5, legend: '04:30' },
    { value: 5, legend: '05:00' },
    { value: 5.5, legend: '05:30' },
    { value: 6, legend: '06:00' },
    { value: 6.5, legend: '06:30' },
    { value: 7, legend: '07:00' },
    { value: 7.5, legend: '07:30' },
    { value: 8, legend: '08:00' },
    { value: 8.5, legend: '08:30' },
    { value: 9, legend: '09:00' },
    { value: 9.5, legend: '09:30' },
    { value: 10, legend: '10:00' },
    { value: 10.5, legend: '10:30' },
    { value: 11, legend: '11:00' },
    { value: 11.5, legend: '11:30' },
    { value: 12, legend: '12:00' },
    { value: 12.5, legend: '12:30' },
    { value: 13, legend: '13:00' },
    { value: 13.5, legend: '13:30' },
    { value: 14, legend: '14:00' },
    { value: 14.5, legend: '14:30' },
    { value: 15, legend: '15:00' },
    { value: 15.5, legend: '15:30' },
    { value: 16, legend: '16:00' },
    { value: 16.5, legend: '16:30' },
    { value: 17, legend: '17:00' },
    { value: 17.5, legend: '17:30' },
    { value: 18, legend: '18:00' },
    { value: 18.5, legend: '18:30' },
    { value: 19, legend: '19:00' },
    { value: 19.5, legend: '19:30' },
    { value: 20, legend: '20:00' },
    { value: 20.5, legend: '20:30' },
    { value: 21, legend: '21:00' },
    { value: 21.5, legend: '21:30' },
    { value: 22, legend: '22:00' },
    { value: 22.5, legend: '22:30' },
    { value: 23, legend: '23:00' },
    { value: 23.5, legend: '23:30' },
    { value: 24, legend: '24:00' },
    // { value: 24.5, legend: '24:30h' }
  ];
/**
   *   Roles des interlocuteurs,
   */
 interlocuteurRoles = [];

  /**
   * Modifier un besoin
   */
  public EDIT_BESOIN = {
    state: false,
    type: '',
    besoin: null
  };

  client;

   /**
   * 
   * ION DATETIME NAME
   */
  ionDate = {
    monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre' ],
    monthShortNames: ['jan', 'fev', 'mar', 'avr', 'mai', 'jui', 'jui', 'aou', 'sep', 'oct', 'nov', 'dec' ],
    dayNames:['dimanche','lundi','mardi','mercredi','jeudi','vendredi','samedi'],
    dayShortNames:['dim','lun','mar','mer','jeu','ven','sam']
  }

  /**
   * Ajout de besoin mutuel
   */

   public nbHeureDynamicMutuel: number = 0;
   public nbHeureDynamicMutuelAffichage: string = '';
   public testNbHeuresRestantes: string = '';

  /**
   * Ajout de besoin
   */
   public ADD_BESOIN_BAR: number = 1;
   public ADD_BESOIN_FROMSAVE: boolean = false;
   public ADD_BESOIN_FROMAGENDA: boolean = false;

   public MISSION_TO_EXPANDED = null;
   public ADD_BESOIN: any = {
    id:null,
     service: null,
     frequence: null,
     date: '',
     time_debut: '',
     time_fin: '',
     description: '',
     frequenceType: "Toutes les semaines",
     duration: '',
     nb_heures:'',
     nb_heures_weekend:'',
     frequenceId:'',
     reccurentArray:[]
   };
   public totalTTC;



   /**
    * 
    * Ajout inter depuis devis
    */
    public MISE_EN_PLANCE_BAR: number = 1;
    public MISE_EN_PLANCE_FROMSAVE: boolean = false;
    public MISE_EN_PLANCE_FROMAGENDA: boolean = false;
    public MISE_EN_PLANCE_TYPEPONCTUEL: any = null;

    public MISE_EN_PLANCE_OBJECT;
    public MISE_EN_PLANCE: any = {
     id:null,
      service: null,
      frequence: null,
      date: '',
      time_debut: '',
      time_fin: '',
      description: '',
      frequenceType: "Toutes les semaines",
      duration: '',
      nb_heures:'',
      nb_heures_weekend:'',
      frequenceId:'',
      reccurentArray:[]
    };
    

   /**
    * Données pour le choix d'intervenant
    */
   public SERVICE_AGENT = {
     dates: [{
              date: 'Lun. 9:00 12:00',
              id: '1'
             },
             {
                date: 'Mar. 9:00 12:00',
                id: '2'
             },
              {
                date: 'Lun. 8:00 10:00',
                id: '3'
              }],
     intervenants: []
   };

   /**
    * Etat selection d'intervenant
    */
   public INTER_STATE = 1;

  /**
  * Type d'affichage de page Agenda
  */
  // typeAgenda: string = 'week';

  /**
  * Type d'affichage de page Bilan
  */
  typeBilan: string = 'month';

  /**
   * Date pour la deduction de la weekend en cours
   */
  weekDate = new Date();

  /**
   * Etat de prolongement d'une mission
   */
  prolonger = false;

   /**
   * Etat de signature d'une mission accomplie
   */
  mission_signed = false;

 /**
   * Commentaire d'une mission
   */
  missio
  missionCommentaires = false;

  /**
   * Etat de bar d'avancement d'inscription
   */
  bar = 1;

   /**
   * Inscription
   * 
   */
    signupData = {
      adresse:''
      ,civilite:''
      ,codep:'',
      country:'France',
      date_naissance:''
      ,email:'',
      fixe:'',
      lat:0,
      lng:0,
      mobile:'',
      nom:'',
      prenom:'',
      ville:''
    }

  /**
   * @ignore
   */
  periodeIndispo = 1;

   /**
   * Etat de bar d'ajout d'indisponibilité
   */
  barIndispo = 1;


  /**
   * Periode d'indisponibilité
   */
  periode = {debut: null, fin: null, time_debut: '08:00', time_fin: '08:00'};

  // Moyens de paiement
  moyenPaiementMessageSuccess;
  moyenPaiementMessageError;

  private moyenPaiementRefreshList = new Subject<any>();
  moyenPaiementRefreshList$ = this.moyenPaiementRefreshList.asObservable();

  constructor(
    private toastController: ToastController,
    private api: ApiService,
    private ionLoaderService: IonLoaderService,
    private authenticationStateService: AuthenticationStateService
    ) { }

  /**
   * Obtenir le mois et l'annnée d'une date donnée
   * @param date objet date à utiliser
   * @returns string
   */
    getMonthYearString(date: Date): string {
      return `${date.toLocaleString('fr-FR', {weekday: 'long', day: 'numeric', month: 'long'})} ${date.getFullYear()}`
    }

   /**
    * Afficher le toast
    * @param msg le message à afficher
    * @param time le temps d'affichage, c'est 2000 par defaut
    */
    async presentToast(msg, time = 2000): Promise<void> {
      const toast = await this.toastController.create({
        message: msg,
        duration: time,
        cssClass: 'w-toast'
      });
      toast.present();
    }


    /**
     * Verifie si l'index existe
     * @param array tableau d'objet à manipuler
     * @param index valeur de l'index
     * @returns boolean true ou false selon l'existance de l'element
     */
    OnCheckIndex(array: any[], index): boolean {
      let value = false;
      array.forEach((ar, i) =>{  value = i === index ? true : value; console.log('i', i); console.log('index', index); } );
      return value;
    }

    private _resetAgenda$ = new BehaviorSubject<boolean>(false);


    public set setResetAgenda(resetStatus: boolean) {
      this._resetAgenda$.next(resetStatus);
    }
  
    public get resetAgenda(): Observable<boolean> {
      return this._resetAgenda$.asObservable();
    }


    private _resetInterlo$ = new BehaviorSubject<boolean>(false);


    public set setResetInterlo(resetInterlo: boolean) {
      this._resetInterlo$.next(resetInterlo);
    }
  
    public get resetInterlo(): Observable<boolean> {
      return this._resetInterlo$.asObservable();
    }

    getDay(i): any {
      switch (i) {
        case 1:
          return { long: 'Lundi', short: 'Lun' };
          break;
        case 2:
          return { long: 'Mardi', short: 'Mar' };
          break;
        case 3:
          return { long: 'Mercredi', short: 'Mer' };
          break;
        case 4:
          return { long: 'Jeudi', short: 'Jeu' };
          break;
        case 5:
          return { long: 'Vendredi', short: 'Ven' };
          break;
        case 6:
          return { long: 'Samedi', short: 'Sam' };
          break;
        case 7:
          return { long: 'Dimanche', short: 'Dim' };
          break;
        default:
          return '';
      }
    }
    getLegend(value: number): string {
      return this.HEURES_RANGER.filter((h) => h.value === value)[0].legend;
    }

    nbNotifsNonLues = 0;
    getNotificationsOfUserSub: Subscription;
    async getNotificationsOfUser(){
      this.nbNotifsNonLues = 0;
      const userId = this.authenticationStateService.userValue?.id;
      this.getNotificationsOfUserSub = this.api.getNotificationsOfUser(userId).subscribe(async (resp) => {
        if(resp && resp?.success){
          resp.datas.forEach(notif => {
            if(!notif.is_read){
              this.nbNotifsNonLues++;
            }
          });
        }else{
          this.presentToast('Erreur lors de la récupération des notifications');
        }
      });
      console.log('nbNotifsNonLues',this.nbNotifsNonLues);
    }

  moyenPaiementRefreshListEvent(){
    this.moyenPaiementRefreshList.next();
  }
    
}
