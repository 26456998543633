import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/authguard/AuthGuard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'profil',
    loadChildren: () => import('./pages/profil/profil.module').then( m => m.ProfilPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'missions',
    loadChildren: () => import('./pages/missions/missions.module').then( m => m.MissionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'besoin',
    loadChildren: () => import('./pages/besoin/besoin.module').then( m => m.BesoinPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'besoin/:idmission',
    loadChildren: () => import('./pages/besoin/besoin.module').then( m => m.BesoinPageModule),
    canActivate: [AuthGuard]
  },
  
  {
    path: 'documents',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'messagerie',
    loadChildren: () => import('./pages/messagerie/messagerie.module').then( m => m.MessageriePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full'
  },
  {
    path: 'facturation-planifies-modal',
    loadChildren: () => import('./components/compte-details/facturation-planifies-modal/facturation-planifies-modal.module').then( m => m.FacturationPlanifiesModalPageModule)
  },
  {
    path: 'scenario-propositions',
    loadChildren: () => import('./pages/scenarios/scenario-propositions/scenario-propositions.module').then( m => m.ScenarioPropositionsPageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
