import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  encryptSecretKey;

  constructor() {
    this.encryptSecretKey = environment.encryptSecretKey;
  }

  CryptoJSAesJson = {
    stringify: function (cipherParams) {
      var j = {
        ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
      } as any;
      if (cipherParams.iv) j.iv = cipherParams.iv.toString();
      if (cipherParams.salt) j.s = cipherParams.salt.toString();
      return JSON.stringify(j);
    },
    parse: function (jsonStr) {
      jsonStr = CryptoJS.enc.Base64.parse(jsonStr);
      jsonStr = CryptoJS.enc.Utf8.stringify(jsonStr);
      var j = JSON.parse(jsonStr);
      var cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(j.ct),
      });
      if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
      if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
      return cipherParams;
    }
  };

  encrypt(data: any) {
    // return CryptoJS.AES.encrypt(
    //   text.toString().trim(),
    //   this.encryptSecretKey.trim()
    // ).toString();

    const encoded = CryptoJS.enc.Utf8.parse(
      CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey, {
        format: this.CryptoJSAesJson,
      }).toString()
    );
    return CryptoJS.enc.Base64.stringify(encoded);
  }

  decrypt(data: any) {
    let returnValue = null;
    try{
      returnValue = JSON.parse(
        CryptoJS.AES.decrypt(data, this.encryptSecretKey, {
          format: this.CryptoJSAesJson,
        }).toString(CryptoJS.enc.Utf8)
      );
    }catch($e){
      returnValue = null;
    }
    return returnValue ? returnValue : null;
  }
}
